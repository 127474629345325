import React from "react"
import PrivacyNigeria from "../../components/body/pages/privacy/privacyNigeria"
import NoheaderFooter from "../../components/Noheaderfooter"
import SEO from "../../components/seo"

const PrivacyPageNg = () => (
  <NoheaderFooter>
    <SEO
      canonical={"https://kuda.com/legal/ng-privacy/"}
      title="Privacy Policy | Kuda | The Money App for Africans"
    />
    <PrivacyNigeria />
  </NoheaderFooter>
)

export default PrivacyPageNg
